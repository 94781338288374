<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6"
                :sm="8"
                :xs="12">
          <el-form-item label="教师id">
            <el-input placeholder="教师id、手机、用户名"
                      style="width:100%"
                      v-model="form.keyword" />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="6">
          <el-form-item label="用户名/姓名">
            <el-input placeholder="请输入用户名/姓名"
                      style="width:100%"
                      v-model="form.edu_user_name" />
          </el-form-item>
        </el-col> -->
        <el-col :span="6"
                :sm="8"
                :xs="12">
          <el-form-item label="起始时间">
            <el-date-picker v-model="form.timeBegin"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6"
                :sm="8"
                :xs="12">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.timeEnd"
                            type="datetime"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间" />
          </el-form-item>
        </el-col>

        <el-col :span="6"
                :lg="6"
                :xl="6"
                :md="6"
                :sm="12"
                :xs="12">
          <el-button type="primary"
                     @click="search">提交</el-button>
          <el-button type="primary"
                     @click="exportExcel">导出</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table border
              stripe
              :data="tableData"
              :summary-method="getSummaries"
              show-summary
              highlight-current-row
              :max-height="$store.state.tableHeight"
              style="width: 100%">
      <el-table-column prop="edu_user_id"
                       align="center"
                       label="教师ID" />
      <el-table-column prop="edu_user_mobile"
                       align="center"
                       label="手机" />
      <el-table-column prop="edu_user_name"
                       align="center"
                       label="用户名" />
      <el-table-column prop="sum"
                       align="center"
                       label="讲义总数" />
      <el-table-column align="center"
                       label="操作">

        <template slot-scope="{row}">
          <div class="text_btn_primary"
               @click="showForm(row)">详情</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <Table ref="TablesRef"
           :tableData="tableData" /> -->
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { now_month_time, nowDateTime } from '@/utils/date.js'
export default {
  data () {
    return {
      tableData: [],
      options: [],
      count: '',
      form: {
        keyword: '',
        timeBegin: now_month_time(),
        timeEnd: nowDateTime()
      },
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'lutiAdmin_admin_teachingMaterials',
      tableColumns: [
        { prop: 'edu_user_id', align: 'center', label: '教师ID', width: '' },
        { prop: 'edu_user_mobile', align: 'center', label: '手机', width: '' },
        { prop: 'edu_user_name', align: 'center', label: '用户名', width: '' },
        { prop: 'sum', align: 'center', label: '讲义总数', width: '' },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '详情', type: 'look', event: 'showForm' },
          ]
        },
      ]
    }
  },
  methods: {
    search () {
      this.$refs.pagination.toFirstPage()
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/lecture',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
        this.count = res.data.allSum
      })
    },
    showForm (row) {

      let form = {
        timeBegin: this.form.timeBegin,
        timeEnd: this.form.timeEnd,
        edu_user_id: row.edu_user_id,
        edu_user_name: row.edu_user_name
      }
      this.$router.push('/teachingMaterials/info?form=' + JSON.stringify(form))
    },
    exportExcel () {
      this.$http({
        url: '/api/v2/stats/lecture_export',
        method: 'get',
        responseType: "blob",
        params: this.form
      }).then(res => {
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        a.download = `讲义统计${this.form.timeBegin}${'-' + this.form.timeEnd}.xlsx`
        a.click()
      })
    },
    getSummaries () {
      const sums = [];
      sums[0] = '合计'
      sums[1] = ''
      sums[2] = ''
      sums[3] = this.count

      return sums;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>